<template>
  <div @click="focusDocument()" style="width: 100% !important;    min-height: 100vh;" >

      <v-snackbar  rounded="" elevation="2"
      v-model="snackbar" :color=colorAlert
      :timeout="timeout"  bottom
    >  <v-icon style="margin-right:5px;">mdi-checkbox-marked-circle-outline</v-icon> 
  <b > {{textAlert}}</b>

     
    </v-snackbar>


    <v-col lg="2" style="padding-bottom:0px;">
      <s-text type="number" ref="document"
        autofocus
        label="Documento"
        v-model="document"
        @keyupEnter="reserve()"
      ></s-text
    ></v-col>


      <v-row justify="center">
        <v-col lg="3" style="margin-left:12px;">
          <v-btn rounded="" 
            block :dark=!getSelection(1)
            x-large="" height="52"
            :color="!getSelection(1)?'success':'white'"
            @click="getDate(1)" elevation="3" class="text-center font-btn-reserve"
            > <b style="font-size:32px;">Hoy</b>  </v-btn
          ></v-col >
        <v-col  lg="3" style="margin-right:12px;padding-left:0px;">
          <v-btn rounded=""
            class="font-btn-reserve"
               block :dark=!getSelection(2)
            x-large="" height="52"
            :color="!getSelection(2)?'warning':'white'"
     
            @click="getDate(2)" elevation="3"
            ><b style="font-size:32px;">Mañana</b> </v-btn
          ></v-col
        ></v-row
      >
<v-divider></v-divider>
 <div class="text-center" style="margin-top:10px;">
    <v-chip   @click="setSelectionMeal(m);"  v-for="(m,i) in typeMeals"  :key="m.TypeMealText"
     class="ma-1" style="font-size:18px !important;" :color="getSelectionMeal(m)==true?'primary':'default'" >
       <v-avatar left>
              <v-img height="50" :src="getImage(m)"></v-img>
            </v-avatar>
       {{m.TypeMealText}} </v-chip>
 </div>

    <v-row>

 
    


      <v-col  v-if="person" style="padding-top:0px;" >
        <v-row justify="center">
          <v-col lg="6">
            <v-card style="padding-bottom:1px;" elevation="3" class="mx-auto">
              <v-list-item>
                <v-list-item-avatar
                  ><img src="./../../../public/static/logo.jpg"
                /></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size:22px;">
                    {{  person.NtpPaternalSurname +
                        " " +
                        person.NtpMaternalSurname+' '+
                      person.NtpName 
                    
                      
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-img height="280" :src="getImage(mealSelection)"></v-img>
              <v-row  class="d-none d-lg-flex d-xl-none"  justify="center" style="margin:15px;">
                <v class="col">
                  <v-btn
                    class="capitalize"
                    color="success"
                    block
                    :outlined="now"
                    style="font-size:18px !important; margin-right:20px;"
                    >Hoy</v-btn
                  > </v
                ><v-col>
                  <v-btn
                    class="capitalize"
                    color="primary"
                    block
                    :outlined="tomorrow"
                    >Mañana</v-btn
                  ></v-col
                >
              </v-row>
            </v-card>
          </v-col>
        </v-row></v-col>
    </v-row>
  </div>
</template>

<script>
import MealTypeService from "./../../services/DinningHall/MealTypeService";
import MealConsumptionMassiveService from "./../../services/DinningHall/MealConsumptionMassiveService";
import MealConsumptionService from "./../../services/DinningHall/MealConsumptionService";
import HelperService from "./../../services/HelperService";
export default {
  name: "ReserveUser",
  components: {},
  data: () => ({item:-1,colorAlert:'',textAlert:'',
    now: false,snackbar:false, timeout:2000,
    tomorrow: false,
    dateSelection: [],
    typeMeals: [],
    mealSelection: null,
    document: "",
    person: null,
    timeRefresh: 0,
  }),
  created() { this.$fun.fullScreen();
    //this.timeRefresh=this.$store.state.TimeRefreshInactive;
    let timerId = setInterval(() => {
      this.timeRefresh = this.timeRefresh + 1000;
      if (this.timeRefresh >= this.$store.state.TimeRefreshInactive) {
        $("body").trigger("mousemove");
        // this.$store.commit('setTimeToken',localStorage.getItem('TimeToken'));
        clearInterval(timerId);
      }
    }, 1000);

    this.getDate(1);
    MealTypeService.list().then((result) => {
      this.typeMeals = result.data;
      if(this.typeMeals.length>0) this.setSelectionMeal(this.typeMeals[0]);
    });
  },
  watch:{
  },
  methods: {
    focusDocument(){

      this.$refs.document.focus();
    },
    getColor(i){},
    reserve() {
      if(this.document.trim().length<1) return;
      this.now = this.getSelection(1);
      this.tomorrow = this.getSelection(2);
      this.reserves = [];
      if (this.mealSelection != null) {
        this.dateSelection.forEach((element) => {
          let obj = {
            McsPersonDocumentNumber: this.document.trim(),
            MltID: this.mealSelection.MltID,
            TypeAccessReserve: 2,
            McsReservationDate: this.$moment()
              .add(element - 1, "d")
              .format("YYYY-MM-DD"),
              UsrCreateID: localStorage.getItem("UsrID"),
          };
          this.reserves.push(obj);
        });
      } else {this.document="";
        this.$fun.alert("Seleccione Menú", "warning");
        return;
      }
      if (this.reserves.length > 0)
        MealConsumptionMassiveService.massiveSave(this.reserves).then((r) => {
          if (r.status == 200) {
             this.$refs.document.focus();
            MealConsumptionService.ReservesPerson(this.document).then((r) => {
              this.person = r.data;
              this.document="";
            });this.document="";
      this.$fun.alert("Reservado Correctamente", "success");
          }else{ this.document="";this.$refs.document.focus();}
           },e=>{
              this.document="";this.$refs.document.focus();
           });
      else {this.document="";
        this.$fun.alert("Elija dias", "warning");
      }
     
    },
    setSelectionMeal(val) {
      this.mealSelection = val;
      this.person = null;
    },
    getSelectionMeal(val) {
      if (this.mealSelection == val) return true;
      else return false;
    },
    getSelection(val) {
      if (this.dateSelection.find((x) => x === val)) {
        return false;
      } else {
        return true;
      }
    },
    getImageReserve() {
      return HelperService.getPhotoMealUrl(m.TypeMeal);
    },
    getImage(m) {
      return HelperService.getPhotoMealUrl(m.TypeMeal);
    },
    getDate(val) {
      if (this.dateSelection.find((x) => x === val))
        this.dateSelection = this.dateSelection.filter((x) => x != val);
      else {
        this.dateSelection.push(val);
      }
      this.person = null;
    },
  },
};
</script>
<style scoped>
span {
    font-size: 32px !important;
}
.back{
  width: 100% !important;
}
</style>
